<template>
  <div>
    <input
      :value="value"
      @input="onInput"
      @change="onChange"
      class="form-control"
      type="text"
      :placeholder="placeholder"
      :id="id"
      maxlength="18"
    />
    <div v-if="!isValid" class="invalid-feedback">
      <span>{{ error }}</span>
    </div>
    <div class="col-md-1 col-sm-2">
      <button
        type="button"
        class="btn btn-outline-primary"
        @click="getDataByCnpj()"
        :disabled="!isValid"
        v-if="!loader.get"
      >
        <i class="bx ri-search-2-line"></i>
      </button>
      <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success"></i>
    </div>
  </div>
</template>

<script>
import { cnpjService } from "@/helpers/easyindustriaapi/cnpj.service";
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    id: {
      type: String,
    },
  },

  data() {
    return {
      error: "",
      loader: {
        get: false
      },
    };
  },

  computed: {
    isValid() {
      if (this.value && this.error.length <= 0) {
        return true;
      }

      return false;
    },
  },

  methods: {
    onInput(event) {
      const value = event.target.value;

      if (!value) {
        this.error = "Digite o cnpj para buscar os dados";
      }

      this.$emit("input", event.target.value);
    },

    onChange(event) {
      this.$emit("change", event.target.value);
    },

    async getDataByCnpj() {
      this.loader.get = !this.loader.get;
      const data = await cnpjService.getCompanyByCnpj(this.value);
      this.loader.get = !this.loader.get;

      if (data.status === "ERROR") {
        this.error = data.message;
      }

      this.$emit("company-info", data);
    },
  },
};
</script>