<script>
// console.clear();
import { http } from "@/helpers/easyindustriaapi/config";
import { notificationMethods } from "@/state/helpers";
import {
  required,
  email,
} from "vuelidate/lib/validators";
import { listEstados } from "@/components/defaults/estados";
import { httpCEP } from "@/helpers/easyindustriaapi/configViaCEP";
import CnpjInput from '@/components/cnpj-input';

export default {
  props: {
    currentEmpresa: { type: Object, required: true },
  },
  components: { CnpjInput },
  data() {
    return {
      loader: {
        get: false
      },
      pessoa: {
        id: null,
        pes_fantasia: null,
        pes_razao: null,
        pes_apelido: null,
        pes_cpfcnpj: '',
        pes_ie: '',
        pes_im: '',
        pes_endereco: '',
        pes_numero: '',
        pes_complemento: '',
        pes_bairro: '',
        cidade_id: null,
        pes_cidade: '',
        estado_id: null,
        pes_uf: '',
        pais_id: 1,
        pes_pais: '',
        pes_cep: '',
        pes_telefone: '',
        pes_celular: '',
        pes_email: '',
        pes_filialid: null,
        pes_pcontato: '',
        pes_indfinal: '',
        pes_txjuros: null,
        pes_txmulta: null,
        pes_crc: null,
        pes_cliente: false,
        pes_fornecedor: false,
        pes_funcionario: false,
        pes_transportador: false,
        pes_contador: false,
        tab_preco: null,
        ativo: true,
        pes_indiedest: null,
      },
      listCidades: [],
      listUFs: [],
      listPaises: [],
      paisDefault: {
        id: 1058,
        nome: "Brasil",
        created_at: null,
        updated_at: null
      },
      inserindo: true,
      submitted: false,
    }
  },
  validations: {
    pessoa: {
      pes_cpfcnpj: { required },
      pes_apelido: { required },
      pes_razao: { required },
      pes_endereco: { required },
      pes_numero: { required },
      pes_bairro: { required },
      cidade_id: { required },
      estado_id: { required },
      pais_id: { required },
      pes_cep: { required },
      pes_email: { required, email },
      pes_filialid: { required },
      pes_indfinal: { required },
      pes_indiedest: { required },
    },
  },
  mounted() {
    this.$emit("newTitle", 'Incluir Pessoa');
    this.listPaises = [this.paisDefault];
    this.pessoa.pais_id = 1;
    this.pessoa.pes_filialid = this.currentEmpresa.id;
    this.listUFs = listEstados;
    this.getCidadesStart(29);
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    legendCPFCNPJ() {
      if ((this.pessoa) && (this.pessoa.pes_cpfcnpj)) {
        return (this.pessoa.pes_cpfcnpj.length > 11) ? 'CNPJ' : 'CPF';
      }

      return 'CPF/CNPJ';
    },
    legendNome() {
      if ((this.pessoa) && (this.pessoa.pes_cpfcnpj)) {
        return (this.pessoa.pes_cpfcnpj.length > 11) ? 'Razão social' : 'Nome';
      }

      return 'Nome/Razão social';
    },
    getCidadeId(objects, key) {
      return objects.map(function (object) {
        return object[key];
      });
    },
    isCNPJ() {
      return this.legendCPFCNPJ === 'CNPJ';
    },
  },
  methods: {
    ...notificationMethods,
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.pessoa.$error) {
        this.makeToast('danger', 'Erro de validação!');
      } else {
        this.setPost();
      }
    },
    setPost() {
      this.$emit('gravar', this.pessoa);
    },
    back() {
      this.$emit('back');
    },
    async setDataFromCnpj(data) {
      if (!data.razao) {
        this.notification.clear;
        this.notification.error('Erro ao consultar CNPJ! Tente novamente mais tarde.');
      }

      if (data) {
        this.pessoa.pes_razao = data.razao;
        this.pessoa.pes_apelido = data.fantasia;
        this.pessoa.pes_fantasia = data.fantasia;
        this.pessoa.pes_cep = data.cep;
        this.pessoa.pes_endereco = data.endereco;
        this.pessoa.pes_numero = data.numero;
        this.pessoa.pes_bairro = data.bairro;
        this.pessoa.pes_complemento = data.complemento;

        const selectedCountry = this.listPaises.filter(pais => pais.nome === this.paisDefault.nome)[0];
        this.pessoa.pais_id = selectedCountry.id;
        this.pessoa.pais = selectedCountry.nome;

        const selectedState = this.listUFs.filter(obj => obj.uf === data.uf)[0];
        this.pessoa.estado_id = selectedState.id;
        this.pessoa.pes_uf = selectedState.uf;

        await this.getCidadesStart(this.pessoa.estado_id);
        const selectedCity = this.listCidades.filter(function (city) {
          let str = city.nome;
          str = str.replace(/[ÀÁÂÃÄÅ]/g, "A");
          str = str.replace(/[àáâãäå]/g, "a");
          str = str.replace(/[ÈÉÊË]/g, "E");
          return str.toLowerCase() === data.cidade.toLowerCase();
        })[0];
        this.pessoa.cidade_id = selectedCity.id;
        this.pessoa.pes_cidade = selectedCity.nome;
      }
    },
    changeLoader(value) {
      this.loader.get = value;
    },
    clearEndereco() {
      this.pessoa.pes_endereco = '';
      this.pessoa.pes_bairro = '';
      this.pessoa.pes_complemento = '';
      this.pessoa.pes_cidade = '';
      this.pessoa.pes_uf = '';
    },
    async getCEP(value) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      var cep = value.replace(/\D/g, '');
      this.loader.get = true;
      await httpCEP.get(cep + '/json')
        .then((response) => {
          this.meuCallbackCEP(response.data);
        })
        .finally(() => {
          this.loader.get = false;
        })
        .catch((err) => {
          console.log(err);
          this.loader.get = false;
        });
    },
    async meuCallbackCEP(conteudo) {
      if (!("erro" in conteudo)) {
        this.clearEndereco();
        this.pessoa.pes_endereco = conteudo.logradouro;
        this.pessoa.pes_bairro = conteudo.bairro;
        this.pessoa.pes_complemento = conteudo.complemento;

        const selectedCountry = this.listPaises.filter(pais => pais.nome.toLowerCase() === this.paisDefault.nome.toLowerCase())[0];
        this.pessoa.pes_pais = selectedCountry.nome;
        this.pessoa.pais_id = selectedCountry.id;

        const selectedState = this.listUFs.filter(obj => obj.uf.toLowerCase() === conteudo.uf.toLowerCase())[0];
        this.pessoa.pes_uf = selectedState.nome;
        this.pessoa.estado_id = selectedState.id;
        await this.getCidadesStart(this.pessoa.estado_id);
        const selectedCity = this.listCidades.filter(cidade => cidade.nome === conteudo.localidade)[0];
        this.pessoa.pes_cidade = conteudo.localidade;
        this.pessoa.cidade_id = selectedCity.id;
      } else {
        //CEP não Encontrado.
        this.clearEndereco();
        alert("CEP não encontrado.");
      }
    },
    async getUFs(event) {
      if (this.pessoa.pais_id != 0) {
        this.loader.get = true;
        await http.get('pais/' + event.target.value + '/estados')
          .then((response) => {
            this.listUFs = response.data;
          })
          .finally(() => {
            this.loader.get = false;
          })
          .catch((err) => {
            console.log(err);
            this.loader.get = false;
          });
      }
      this.pessoa.pais_id = event.target.value;
      this.loader.get = false;
    },
    async getCidadesStart(estadoId) {
      let id = (estadoId && estadoId.target) ? estadoId.target.value : estadoId;
      this.loader.get = true;
      await http.get('pais/' + this.pessoa.pais_id + '/estado/' + id + '/cidades')
        .then((response) => {
          this.listCidades = response.data;
        })
        .finally(() => {
          this.loader.get = false;
        })
        .catch((err) => {
          console.log(err);
          this.loader.get = false;
        });
      this.pessoa.estado_id = id;
    },
    setCidade(event) {
      this.pessoa.cidade_id = event.target.value;
    },
  },
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
      <form action="#" class="needs-validation" @submit.prevent="formSubmit" ref="form">
        <div v-if="!loader.get" class="row">
          <div class="col-md-2 mt-4 ">
            <div class="card">
              <label for="">Cliente</label>
              <b-form-checkbox v-model="pessoa.pes_cliente" id="produto-ativo1" name="ativo" value="-1">
                001-Cliente
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-2 mt-4 ">
            <div class="card">
              <label for="">Fornecedor</label>
              <b-form-checkbox v-model="pessoa.pes_fornecedor" id="produto-ativo2" name="ativo" value="-2">
                002-Fornecedor
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-3 mt-4 ">
            <div class="card">
              <label for="">Transportadora</label>
              <b-form-checkbox v-model="pessoa.pes_transportador" id="produto-ativo3" name="ativo" value="-3">
                003-Transportadora
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-2 mt-4">
            <div class="card">
              <label for="">Contador</label>
              <b-form-checkbox v-model="pessoa.pes_contador" id="produto-ativo4" name="ativo" value="-3">
                004-Contador
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-2 mt-4">
            <div class="card">
              <label for="">Funcionario</label>
              <b-form-checkbox v-model="pessoa.pes_funcionario" id="produto-ativo5" name="ativo" value="-3">
                005-Funcionario
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row mb-3">
              <label for="pes_cpfcnpj" class="col-sm-2 col-form-label">{{ legendCPFCNPJ }}</label>
              <div class="col-md-3 col-sm-12">
                <CnpjInput :placeholder="legendCPFCNPJ" v-model="pessoa.pes_cpfcnpj" @company-info="setDataFromCnpj"
                  :id="'pes_cpfcnpj'" :class="{ 'is-invalid': submitted && $v.pessoa.pes_cpfcnpj.$error }" />
                <div v-if="submitted && $v.pessoa.pes_cpfcnpj.$error" class="invalid-feedback">
                  <span v-if="!$v.pessoa.pes_cpfcnpj.required"> {{ $t('mesages.validation.required') }}</span>
                </div>
              </div>
              <label for="pes_apelido" class="col-md-2 col-sm-12 col-form-label">Apelido</label>
              <div class="col-md-4 col-sm-12">
                <input v-model="pessoa.pes_apelido" class="form-control" type="text" placeholder="Apelido do Cliente"
                  id="pes_apelido" :class="{ 'is-invalid': submitted && $v.pessoa.pes_apelido.$error }">
                <div v-if="submitted && $v.pessoa.pes_apelido.$error" class="invalid-feedback">
                  <span v-if="!$v.pessoa.pes_apelido.required"> {{ $t('mesages.validation.required') }}</span>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <label for="pes_razao" class="col-md-2 col-sm-12 col-form-label">{{ legendNome }}</label>
              <div :class="isCNPJ ? 'col-md-4' : 'col-md-10'" class="col-sm-12">
                <input v-model="pessoa.pes_razao" class="form-control" type="text"
                  :placeholder="legendNome + ' do Cliente'" id="pes_razao"
                  :class="{ 'is-invalid': submitted && $v.pessoa.pes_razao.$error }">
                <div v-if="submitted && $v.pessoa.pes_razao.$error" class="invalid-feedback">
                  <span v-if="!$v.pessoa.pes_razao.required"> {{ $t('mesages.validation.required') }}</span>
                </div>
              </div>
              <label v-if="isCNPJ" for="pes_fantasia" class="col-md-2 col-form-label">Nome Fantasia</label>
              <div v-if="isCNPJ" class="col-md-4 col-sm-12">
                <input v-model="pessoa.pes_fantasia" class="form-control" type="text"
                  placeholder="Nome fantasia do Cliente" id="pes_fantasia"
                  :class="{ 'is-invalid': isCNPJ && submitted && $v.pessoa.pes_fantasia.$error }">
                <div v-if="isCNPJ && submitted && $v.pessoa.pes_fantasia.$error" class="invalid-feedback">
                  <span v-if="isCNPJ && !$v.pessoa.pes_fantasia.required"> {{ $t('mesages.validation.required') }}</span>
                </div>
              </div>
            </div>
            <div v-if="isCNPJ" class="row mb-3">
              <label for="pes_ie" class="col-md-2 col-sm-12 col-form-label">Inscriçãoo Estadual</label>
              <div class="col-md-4 col-sm-12">
                <input v-model="pessoa.pes_ie" class="form-control" type="text" placeholder="IE do Cliente" id="pes_ie"
                  maxlength="14">
              </div>
              <label for="pes_im" class="col-md-2 col-form-label">Inscrição Municipal</label>
              <div class="col-md-4 col-sm-12">
                <input v-model="pessoa.pes_im" class="form-control" type="text" placeholder="IM do Cliente" id="pes_im">
              </div>
            </div>
            <div class="row mb-3">
              <label for="pes_cep" class="col-md-2 col-form-label">CEP</label>
              <div class="col-md-3 col-sm-10">
                <input ref="pes_cep" v-model="pessoa.pes_cep" v-mask="'#####-###'" type="text" placeholder="00000-000"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.pessoa.pes_cep.$error }" />
                <div v-if="submitted && $v.pessoa.pes_cep.$error" class="invalid-feedback">
                  <span v-if="!$v.pessoa.pes_cep.required"> {{ $t('mesages.validation.required') }}</span>
                </div>
              </div>
              <div class="col-md-1 col-sm-2">
                <button type="button" class="btn btn-outline-primary" @click="getCEP(pessoa.pes_cep)">
                  <i class="bx ri-search-2-line"></i>
                </button>
              </div>
              <label for="pes_endereco" class="col-sm-2 col-form-label">Endereço</label>
              <div class="col-md-4 col-sm-12">
                <input v-model="pessoa.pes_endereco" class="form-control" type="text"
                  placeholder="Rua/Logadouro/Avenida..." id="pes_endereco" maxlength="200"
                  :class="{ 'is-invalid': submitted && $v.pessoa.pes_endereco.$error }">
                <div v-if="submitted && $v.pessoa.pes_endereco.$error" class="invalid-feedback">
                  <span v-if="!$v.pessoa.pes_endereco.required"> {{ $t('mesages.validation.required') }}</span>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <label for="pes_numero" class="col-md-2 col-sm-12 col-form-label">Número</label>
              <div class="col-md-4 col-sm-12">
                <input v-model="pessoa.pes_numero" class="form-control" type="text" placeholder="SN, 001, 200"
                  id="pes_numero" maxlength="60" :class="{ 'is-invalid': submitted && $v.pessoa.pes_numero.$error }">
                <div v-if="submitted && $v.pessoa.pes_numero.$error" class="invalid-feedback">
                  <span v-if="!$v.pessoa.pes_numero.required"> {{ $t('mesages.validation.required') }}</span>
                </div>
              </div>
              <label for="pes_bairro" class="col-md-2 col-form-label">Bairro</label>
              <div class="col-md-4 col-sm-12">
                <input v-model="pessoa.pes_bairro" class="form-control" type="text" placeholder="Bairro do Cliente"
                  id="pes_bairro" :class="{ 'is-invalid': submitted && $v.pessoa.pes_bairro.$error }">
                <div v-if="submitted && $v.pessoa.pes_bairro.$error" class="invalid-feedback">
                  <span v-if="!$v.pessoa.pes_bairro.required"> {{ $t('mesages.validation.required') }}</span>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <label for="pes_complemento" class="col-md-2 col-sm-12 col-form-label">Complemento</label>
              <div class="col-md-4 col-sm-12">
                <input v-model="pessoa.pes_complemento" class="form-control" type="text"
                  placeholder="Apartamento/KM/Quadra" id="pes_complemento" maxlength="60">
              </div>
              <label for="pes_pais" class="col-md-2 col-form-label">País</label>
              <div class="col-md-4 col-sm-12">
                <select v-model="pessoa.pais_id" class="form-control" id="pes_pais">
                  <option value="0">Selecione o País</option>
                  <option v-for="pais in listPaises" :key="pais.id" :value="pais.id"
                    v-bind:selected="(pessoa.pais_id == pais.id) ? 'selected' : ''">{{ pais.nome }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label for="pes_uf" class="col-md-2 col-form-label">UF</label>
              <div class="col-md-4 col-sm-12">
                <select @change="getCidadesStart($event)" class="form-control" id="pes_uf">
                  <option value="0">Selecione o Estado</option>
                  <option v-for="uf in listUFs" :key="uf.id" :value="uf.id"
                    v-bind:selected="(pessoa.estado_id == uf.id) ? 'selected' : ''">{{ uf.nome }}</option>
                </select>
              </div>
              <label for="pes_cidade" class="col-md-2 col-form-label">Cidade</label>
              <div class="col-md-4 col-sm-12">
                <select @change="setCidade($event)" v-model="pessoa.cidade_id" class="form-control" id="pes_cidade">
                  <option value="0">Selecione a Cidade</option>
                  <option v-for="cidade in listCidades" :key="cidade.id" :value="cidade.id"
                    v-bind:selected="(pessoa.pes_cidade == cidade.id) ? 'selected' : ''">{{ cidade.nome }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label for="pes_email" class="col-sm-2 col-form-label">E-mail</label>
              <div class="col-md-4 col-sm-12">
                <input id="pes_email" v-model="pessoa.pes_email" type="email" name="pes_email" class="form-control"
                  placeholder="E-mail do Cliente" :class="{ 'is-invalid': submitted && $v.pessoa.pes_email.$error }" />
                <div v-if="submitted && $v.pessoa.pes_email.$error" class="invalid-feedback">
                  <span v-if="!$v.pessoa.pes_email.required"> {{ $t('mesages.validation.required') }}</span>
                </div>
              </div>
              <label for="pes_telefone" class="col-sm-2 col-form-label">Telefone</label>
              <div class="col-md-4 col-sm-12">
                <input v-model="pessoa.pes_telefone"
                  v-mask="(pessoa.pes_telefone.length > 10) ? '(##) # ####-####' : '(##) ####-####'" type="text"
                  class="form-control" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <b-button type="button" @click="back" class="m-md-2">Voltar</b-button>
        <b-button type="submit" @click="formSubmit" class="m-md-2">Gravar</b-button>
      </div>
    </div>
  </div>
</template>