import * as axios from 'axios';

import { API } from './config';
import { authHeader } from './auth-header';

const getCompanyByCnpj = async (cnpj) => {
  const requestOptions = {
    headers: authHeader()
  };

  try {
    const response = await axios.get(`${API}/cnpj/${cnpj}`, requestOptions);

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
  }
}

export const cnpjService = { getCompanyByCnpj }